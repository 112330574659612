<template>
   <div class="mode-wrapper">
      <erji-carousel></erji-carousel>
<!--     业务概况-->
     <div class="ab-text">
      <div class="ab-title animate__bounceIn" >
         <p>—— 业务概况 ——</p>
       </div>
      <div class="ab-desc animate__bounceIn">
        <p>构建生态体系</p>
      </div>
    </div>
     <div class="yz-img-con">
         <img src="../../../assets/images/fro01.png">
     </div>
<!--     院校合作-->
    <div class="ab-text">
      <div class="ab-title animate__bounceIn" >
         <p>—— 院校合作 ——</p>
       </div>
      <div class="ab-desc animate__bounceIn">
        <p></p>
      </div>
    </div>

     <div class="mode-top">
         <div class="mode-item left">
           <p class="mode-title">产业入校：共建二级学院&专业</p>
           <div class="mode-text">
              <p>①潍坊环境工程职业学院共建6大新兴
                专业群：电子商务、工业机器人技术、
                物联网应用技术、大数据技术、虚拟现
                实技术应用、机电一体化技术；</p>
              <p>②山东商业职业技术学院：共建跨境电
              商产业孵化实训室；</p>
              <p>③重庆公共运输职业学院：共建数字贸
              易产业学院。</p>
             </div>
         </div>
         <div class="mode-item" style="margin-top:40px;">
            <img src="../../../assets/images/model04.png">
         </div>
         <div class="mode-item  left">
            <p class="mode-title ri-title">产业入校：共建二级学院&专业</p>
             <div class="mode-text">
                <p>①潍坊环境工程职业学院共建6大新兴
                  专业群：电子商务、工业机器人技术、
                  物联网应用技术、大数据技术、虚拟现
                  实技术应用、机电一体化技术；</p>
                <p>②山东商业职业技术学院：共建跨境电
                  商产业孵化实训室；</p>
                <p>③重庆公共运输职业学院：共建数字贸
                易产业学院。</p>
              </div>
         </div>
       </div>
      <!--       跨境电商-->
     <div class="mode-con-item">
          <p class="mode-con-title">跨境电商专业—面向高职院校提供人才培养方案，课程体系，建立实训基地</p>
          <div class="mode-con-text">
              <div class="con-text-img">
                  <img src="../../../assets/images/model07.png">
                  <p class="img-desc">跨境电商专业实训中心</p>
              </div>
             <div class="con-text-img">
                  <img src="../../../assets/images/model08.png">
                  <p class="img-desc">跨境电商专业实训平台</p>
             </div>
          </div>
       </div>
<!--     智能制造-->
      <div class="mode-con-item">
          <p class="mode-con-title">智能制造专业：面向高职院校提供人才培养方案，课程体系，建立实训基地</p>
          <div class="mode-con-text">
              <div class="con-text-img">
                  <img src="../../../assets/images/model09.png">
                  <p class="img-desc">3KG桌面式机器人多功能实训平台</p>
              </div>
             <div class="con-text-img">
                  <img src="../../../assets/images/model10.png">
                  <p class="img-desc">可拆装机器人实训设备</p>
             </div>
          </div>
       </div>
<!--      学历-->
       <div class="mode-con-item">
          <p class="mode-con-title t1">成教专科、本科学历项目：面向本科院校继教学院开展项目合作，提供在线学习平台、课程资源、学院创收</p>
          <p class="erji-title">四川外国语大学 网络与继续教育学院</p>
         <div class="mode-con-text">
              <div class="con-text-img">
                  <p class="text-p">成教本科</p>
                  <hr class="text-fenge">
                  <img src="../../../assets/images/model05.png" class="text-img">
                  <p class="img-desc text-desc">”线上学习+线下实训“：开展网络与新媒体、人力资源管理两
                    个专业的成教本科学历项目合作，革新传统成教学历线下学习，
                    提供线上学习方式进行升级迭代，并依托公司线下产业基地，为
                    学院线下实训提供保障与支持。差异化开展创新型成教本科学历
                    项目，致力于打造新模式、新标杆。</p>
              </div>
              <div class="con-text-img">
                  <p class="text-p">成教专科</p>
                  <hr class="text-fenge">
                  <img src="../../../assets/images/model06.png" class="text-img">
                  <p class="img-desc text-desc">“学历+技能”：开展英语与日语两个专业的成教专科学历项目合作，为学生增设：跨境电商、网络营销、新媒体、学前教育、亲子艺术等技能专业课程，学生毕业不仅拿学历，更能获得实用性就业技能。很好提高学校的就业率与品牌美誉度。</p>
               </div>
          </div>
       </div>
   </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
 .mode-wrapper{
   .yz-img-con{
       width: 100%;
       height: 550px;
       padding-top:70px;
       background: url("../../../assets/images/AI-back.png");
   }
   .mode-top{
     padding:40px 0px;
     width: 1200px;
     margin:0 auto;
     display: flex;
     display: -webkit-flex; /* Safari */
      flex-wrap: wrap;
      justify-content: space-between;
     .mode-item{
       flex:0 0 30%;
       .mode-title{
         width: 100%;
         height: 63px;
         line-height:63px;
         text-align: center;
         background: #FFA854;
         border-radius: 10px 10px 0px 0px;
         font-size: 20px;
         font-weight: bold;
         color: #FFFFFF;
       }
       .mode-text{
          padding:20px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          text-align: left;
       }
     }
      .left {
       width: 350px;
       height: 337px;
       background: #F7F7F7;
       border-radius: 10px;
     }
     .ri-title{
       background: #0184d2 !important;
     }
   }
   .mode-con-item{
     width:1200px;
     margin:0 auto;
     .mode-con-title{
        width: 769px;
        height: 56px;
        background: #E5F5FF;
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 56px;
        margin:0 auto;
     }
     .erji-title{
       margin-top:20px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 38px;
     }
     .mode-con-text{
       margin:0 auto;
       width: 769px;
       padding:20px;
       display: flex;
       display: -webkit-flex; /* Safari */
       flex-wrap: wrap;
       justify-content: space-between;
       .con-text-img{
          flex:0 48%;
         img{
           width: 274px;
           height: 163px;
         }
         .img-desc{
           margin-top:18px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 30px;
         }
         .text-p{
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          line-height: 45px;
         }
        .text-fenge{
          width: 43px;
         height: 2px;
         background: #4CBAFF;
         margin:0 auto;margin-bottom:15px;
        }
         .text-img{
           width: 193px;
           height: 145px;
         }
         .text-desc{
            font-size: 16px;
            font-weight: 400;
            text-align: left;
         }
       }
     }
   }
 }
 .t1{
   width: 1070px !important;
 }
</style>
<style lang="less">
 .ab-text{
           margin:40px 0;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
</style>
